<template>
  <div
    style="
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    "
  >
    <div class="confirmModal">
      <div class="confirmModal-header">
        <p class="confirmModal-title">{{ title }}?</p>
        <p>{{ confirmModalStrings.warning }}</p>
      </div>
      <div class="confirmModal-buttons">
        <button class="button button--ghost" @click="cancel">
          {{ confirmModalStrings.cancel }}
        </button>
        <button class="button button--ghost" @click="confirm">
          {{ action }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ConfirmModalStrings } from "../Shared/strings";

export default {
  data() {
    return {
      inputValue: this.name,
      serverResponse: "",
      isLoading: false,
    };
  },
  props: ["title", "action", "payload"],
  computed: {
    confirmModalStrings() {
      return ConfirmModalStrings;
    },
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm", this.payload);
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss">
.confirmModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 2;
  width: 25em;
  border: 2px solid #f1f3f9;
  padding: 12px;
  border-radius: 8px;
  &-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }
  &-header {
    margin-bottom: 24px;
  }
  &-buttons {
    display: flex;
    gap: 8px;
    button {
      flex: 1;
      &:hover {
        background-color: var(--clr-danger-700);
        border-color: var(--clr-danger-700);
        color: white;
      }
    }
  }
}
.userManagment-form-right-content-select {
  display: block;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.8em;
  width: 13em;
  margin-bottom: 1.25em;
  padding: 0.4em;
}
</style>
