<template>
  <div class="orgManagement">
    <div class="orgManagement-section">
      <div class="orgManagement-section-header">
        <h3 class="orgManagement-section-header-title">
          Organization Management
        </h3>
        <div class="buttons">
          <button
            v-if="roleLoggedUser === 'SuperAdmin'"
            @click="showAddPartner = !showAddPartner"
            class="orgManagement-section-header-button"
          >
            + Partner
          </button>
          <button
            v-if="roleLoggedUser === 'SuperAdmin'"
            @click="showAddServiceProvider = !showAddServiceProvider"
            class="orgManagement-section-header-button"
          >
            + Service Provider
          </button>
        </div>
        <div class="title">
          <h3>{{ selectedOrg.name }}</h3>
          <h4>{{ selectedOrg.numUsers }} users</h4>
        </div>
      </div>
      <div class="orgManagement-section-body">
        <table v-if="hasServiceProviders" class="table">
          <tr class="table-header">
            <th v-for="(item, index) in headers" :key="index">{{ item }}</th>
          </tr>
          <tr
            v-for="(item, index) in selectedOrg.serviceProviders"
            :key="index"
            :data-id="item.id"
            class="table-content"
            @click="onRowClick(item.name, item.id, $event)"
          >
            <td class="title">
              {{ item.name }}
            </td>
            <td>
              {{ item.numUsers || 0 }}
            </td>
            <td class="kebabs">
              <Kebab
                @menuItemChanged="onKebabChange"
                :payload="item"
                :options="options"
                v-if="roleLoggedUser === 'SuperAdmin'"
              />
            </td>
          </tr>
        </table>
        <ConfirmModal
          v-if="showConfirmModal"
          @close="closeConfirmModal"
          @confirm="deleteOrganization"
          :title="`${confirmModalStrings.title} delete ${name}`"
          :payload="id"
          :action="confirmModalType"
          :name="name"
        />
        <OrgForm
          v-if="showAddPartner"
          @close="closeOrgForm"
          :action="'Add'"
          :type="'Partner'"
        />
        <OrgForm
          v-if="showAddServiceProvider"
          @close="closeOrgForm"
          :action="'Add'"
          :type="'Service Provider'"
        />
        <OrgForm
          v-if="showEditForm"
          @close="closeOrgForm"
          :action="'Edit'"
          :type="'Service Provider'"
          :name="name"
          :id="id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrgForm from "../Forms/OrgForm.vue";
import Kebab from "../Shared/Kebab.vue";
import { ConfirmModalStrings } from "../Shared/strings";
import ConfirmModal from "../Shared/ConfirmModal.vue";

export default {
  data() {
    return {
      showAddPartner: false,
      showAddServiceProvider: false,
      showEditServiceProvider: false,
      showEditForm: false,
      showConfirmModal: false,
      confirmModalType: "",
      headers: ["Name", "# of Users", ""],
      options: {
        edit: {
          value: "edit",
          label: "Edit",
        },
        delete: {
          value: "delete",
          label: "Delete",
        },
      },
      id: "",
      name: "",
    };
  },
  components: { Kebab, OrgForm, ConfirmModal },
  computed: {
    roleLoggedUser() {
      return this.$store.getters.roleLoggedUser;
    },
    selectedOrg() {
      return this.$store.getters.getSelectedOrg;
    },
    confirmModalStrings() {
      return ConfirmModalStrings;
    },
    hasServiceProviders() {
      return this.selectedOrg.type == "Partner"
        ? this.selectedOrg.serviceProviders.length
        : false;
    },
  },
  methods: {
    closeOrgForm() {
      this.showAddPartner = false;
      this.showAddServiceProvider = false;
      this.showEditForm = false;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    async onKebabChange(selected, data) {
      if (selected.value === this.options.edit.value) {
        this.name = data.name;
        this.id = data.id;
        this.showEditForm = true;
      }
      if (selected.value === this.options.delete.value) {
        this.id = data.id;
        this.confirmModalType = "Delete";
        this.name = data.name;
        this.showConfirmModal = true;
      }
    },
    async deleteOrganization(id) {
      this.$store.dispatch("startLoading", "deleteOrg");
      try {
        await this.$store.dispatch("deleteOrganizationAction", id);
      } finally {
        this.$store.dispatch("endLoading", "deleteOrg");
      }
    },
    onRowClick(name, id) {
      if (this.roleLoggedUser === "SuperAdmin") {
        this.name = name;
        this.id = id;
        this.showEditForm = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.orgManagement {
  &-section {
    &-header {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: space-between;
      margin-top: 1.5em;
      &-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 600;
      }
      .title {
        width: 100%;
        display: flex;
        gap: 24px;
        align-items: center;
        h3,
        h4 {
          margin-top: 0;
        }
      }
    }
  }
  .buttons {
    display: flex;
    gap: 8px;
    button {
      align-self: center;
    }
  }
}
</style>
