<template>
  <form @submit.prevent="savePassword" class="password-section">
    <h2 class="password-section-title">Password</h2>
    <div class="password-section-cont">
      <!-- Current Password -->
      <label class="password-section-cont-label" for="current_password">
        Current Password
      </label>
      <FieldPassword
        :value="currentPassword"
        @passwordChanged="onCurrentChange"
      />
    </div>
    <!-- New Password -->
    <div class="password-section-cont">
      <label class="password-section-cont-label" for="new_password">
        New Password
      </label>
      <FieldPassword :value="newPassword" @passwordChanged="onNewChange" />
    </div>
    <!-- Confirm New Password -->
    <div class="password-section-cont">
      <label class="password-section-cont-label" for="confirm_new_password">
        Confirm New Password
      </label>
      <FieldPassword
        :value="confirmNewPassword"
        @passwordChanged="onNewConfirmChange"
      />
      <p class="password-section-cont-helpMessage-error" v-if="!passwordsMatch">
        {{ passwordMismatchMessage }}
      </p>
    </div>
    <p class="password-section-cont-helpMessage">
      {{ passwordStrings.requirements }}
    </p>
    <LoadingButton
      type="submit"
      text="Save"
      :isLoading="isLoading"
      :disabled="
        !passwordsMatch ||
        !currentPassword ||
        !newPassword ||
        !confirmNewPassword
      "
    />
    <p v-if="errorMessage" class="form-error-message">
      {{ this.errorMessage }}
    </p>
  </form>
</template>

<script>
import { changePassword } from "../../api/cognito";
import LoadingButton from "../Shared/LoadingButton.vue";
import { ToastMessages, Passwords } from "../Shared/strings";
import FieldPassword from "../Forms/FieldPassword.vue";

export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      errorMessage: "",
      isLoading: false,
    };
  },
  components: { LoadingButton, FieldPassword },
  computed: {
    passwordsMatch() {
      return this.newPassword === this.confirmNewPassword;
    },
    passwordMismatchMessage() {
      return ToastMessages.user.password.mismatch.error;
    },
    passwordStrings() {
      return Passwords;
    },
  },
  methods: {
    savePassword() {
      this.isLoading = true;
      this.errorMessage = "";
      if (this.passwordsMatch) {
        changePassword(
          this.currentPassword,
          this.newPassword,
          this.passwordChangeCallback,
        );
      }
    },
    passwordChangeCallback(err, result) {
      this.isLoading = false;
      if (err) {
        this.errorMessage = err;
      } else if (result) {
        this.resetForm();
        this.$store.dispatch("showToast", {
          message: ToastMessages.user.password.change.success,
          type: "success",
        });
      }
    },
    resetForm() {
      this.currentPassword = "";
      (this.newPassword = ""),
        (this.confirmNewPassword = ""),
        (this.errorMessage = "");
    },
    onCurrentChange(currentPassword) {
      this.currentPassword = currentPassword;
    },
    onNewChange(newPassword) {
      this.newPassword = newPassword;
    },
    onNewConfirmChange(newPasswordConfirm) {
      this.confirmNewPassword = newPasswordConfirm;
    },
  },
};
</script>

<style scoped lang="scss">
.password-section {
  color: #1d2432;
  max-width: 30.6em;
  &-title {
    margin: 1em 0 1.1em;
    font-size: 20px;
    font-weight: 600;
  }
  &-cont {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.4em;
    &-label {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 0.5em;
    }
    &-input {
      border-radius: 0.5em;
      border: solid 1px rgba(204, 204, 204, 0.829);
      height: 2.25em;
      width: 36em;
      padding-left: 0.5em;
      @media screen and (max-width: 490px) {
        width: 25em;
      }
    }
    &-helpMessage {
      font-size: 0.75em;
      color: #85878d;
      margin-top: 5px;
      margin-bottom: 2px;
      &-error {
        font-size: 0.75em;
        color: red;
      }
    }
  }
  &-button {
    margin-top: 16px;
  }
  .form-error-message {
    color: var(--clr-danger-700);
  }
}
</style>
