<template>
  <div class="users">
    <SelectMenu
      :options="organizations"
      :selected="selectedProvider"
      :modifier="'bold'"
      @menuItemChanged="onProviderChanged"
    />
    <FilterUsers @resetOrganizationFilter="resetProvider" />
    <table class="table" cellspacing="0">
      <tr class="users-table-header">
        <th class="users-table-header-title-name">
          Name<img
            class="arrow-up-down"
            alt="arrow-up-down"
            src="../../assets/arrow-up-down.png"
          />
        </th>
        <th class="users-table-header-title-email">Email</th>
        <th class="users-table-header-title-phone">Phone Number</th>
        <th class="users-table-header-title-org">Organization</th>
        <th class="users-table-header-title-role">Role</th>
        <th class="users-table-header-title-last">Last Accessed</th>
        <th class="users-table-header-title"></th>
      </tr>

      <tr
        v-for="user in users"
        :key="user.id"
        class="users-table-content"
        @click="openEditUserDrawer(user)"
      >
        <td class="users-table-content-title">
          <div class="users-table-content-title-name">
            <p class="users-table-content-title-name-t1">
              {{ user.firstName }} {{ user.lastName }}
            </p>
            <p class="users-table-content-title-name-t2">{{ user.title }}</p>
          </div>
        </td>
        <td class="users-table-content-title">
          <div class="users-table-content-title-email">
            <p>{{ user.email }}</p>
          </div>
        </td>
        <td class="users-table-content-title">
          <div class="users-table-content-title-phone">
            {{ user.phone }}
          </div>
        </td>
        <td class="users-table-content-title">
          <Pill class="users-table-content-title-sym label-medium">
            <template #label>
              {{
                user.roleName === "SuperAdmin"
                  ? "Charity: water"
                  : user.organizationName
              }}
            </template>
          </Pill>
        </td>
        <td class="users-table-content-title">
          <Pill class="users-table-content-title-sym label-medium">
            <template #label>
              {{ getRoleDisplayName(user.roleName) }}
            </template>
          </Pill>
        </td>
        <td class="users-table-content-title">
          {{ getUserLastAccessed(user.lastAccessed) }}
        </td>
        <td class="users-table-content-title">
          <Kebab
            :options="kebabOptions"
            :payload="user"
            @menuItemChanged="handleSidePanel"
          />
        </td>
      </tr>
    </table>
    <ConfirmModal
      v-if="showConfirmModal"
      @close="closeConfirmModal"
      @confirm="deleteUser"
      :title="`${confirmModalStrings.title} delete ${userSelectedForEdit.firstName} ${userSelectedForEdit.lastName}`"
      :payload="userSelectedForEdit"
      :action="confirmModalType"
    />
    <Drawer :show.sync="showUserDrawer">
      <template>
        <UserForm
          @close="closeUserDrawer"
          :isEdit="true"
          :userSelectedForEdit="userSelectedForEdit"
        />
      </template>
    </Drawer>
  </div>
</template>

<script>
import FilterUsers from "./FilterUsers.vue";
import UserForm from "../Forms/UserForm.vue";
import Pill from "../Shared/Pill.vue";
import Kebab from "../Shared/Kebab.vue";
import SelectMenu from "../Shared/SelectMenu.vue";
import Drawer from "../Shared/Drawer.vue";
import ConfirmModal from "../Shared/ConfirmModal.vue";
import { ConfirmModalStrings } from "../Shared/strings";

export default {
  components: {
    Drawer,
    FilterUsers,
    UserForm,
    Kebab,
    Pill,
    SelectMenu,
    ConfirmModal,
  },
  data() {
    return {
      selectedProvider: {},
      showUserDrawer: false,
      userSelectedForEdit: {},
      roleLabelValue: [
        { label: "All", value: "all" },
        { label: "Super Admin", value: "SuperAdmin" },
        { label: "Admin", value: "Admin" },
        { label: "View / Edit", value: "ViewEdit" },
        { label: "View Only", value: "ViewOnly" },
        { label: "No Access", value: "NoAccess" },
      ],
      kebabOptions: {
        edit: {
          value: "edit",
          label: "Edit",
        },
        delete: {
          value: "delete",
          label: "Delete",
        },
      },
      showConfirmModal: false,
      confirmModalType: "Delete",
    };
  },
  computed: {
    selectedOrg() {
      return this.$store.getters.getSelectedOrg;
    },
    organizations() {
      const orgOptions = {};
      if (this.hasServiceProviderUsers) {
        orgOptions["all"] = {
          value: "",
          label: "All Service Providers",
        };
      }
      orgOptions[this.selectedOrg.id] = {
        value: this.selectedOrg.id,
        label: this.selectedOrg.name,
      };
      if (this.selectedOrg.type === "Partner") {
        this.selectedOrg.serviceProviders.forEach((provider) => {
          if (provider.numUsers > 0) {
            orgOptions[provider.id] = {
              value: provider.id,
              label: provider.name,
            };
          }
        });
      }
      return orgOptions;
    },
    hasServiceProviderUsers() {
      let ret = false;
      if (this.selectedOrg.type === "Partner") {
        ret = this.selectedOrg.serviceProviders.reduce(
          (sum, sp) => sum + sp.numUsers,
          0,
        );
      }
      return ret;
    },
    users() {
      let ret = [];
      if (!this.$store.state.filterOrganization.length) {
        ret = this.$store.state.filteredUsersByRole;
      } else {
        ret = this.$store.state.filteredUsersByOrganization;
      }
      // ensure no duplicated user
      ret = [...new Map(ret.map((user) => [user.id, user])).values()];
      return ret;
    },
    roles() {
      return this.$store.state.roles;
    },
    options() {
      return this.$store.state.filteredUsersByRole
        .map((elem) => elem.organizationName)
        .filter((item, index, self) => self.indexOf(item) === index);
    },
    confirmModalStrings() {
      return ConfirmModalStrings;
    },
  },
  methods: {
    updateUsers() {
      let filterValue = this.selectedProvider.value
        ? this.selectedProvider.label
        : "";
      this.$store.dispatch("filterUsersByOrganization", filterValue);
    },
    onProviderChanged(item) {
      this.selectedProvider = item;
      this.updateUsers();
    },
    resetProvider() {
      // Set to the first provider
      const firstOrg = Object.values(this.organizations)[0];
      this.selectedProvider = {
        // can only filter by role if org filter is set to ""
        // TODO: clean up this logic
        value: "",
        label: firstOrg.label,
      };
      this.onProviderChanged(this.selectedProvider);
    },
    getRoleDisplayName(roleName) {
      const foundRole = this.roleLabelValue.find(
        (elem) => elem.value === roleName,
      );
      return foundRole ? foundRole.label : "-";
    },
    getUserLastAccessed(access) {
      if (!access) {
        return;
      }
      const dt = new Date(access.slice(0, -6));
      const formatted_dt = dt
        .toLocaleString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        .replace(",", "");
      const result = `${formatted_dt}`.toUpperCase();
      return result;
    },
    handleSidePanel(selectedOption, user) {
      if (selectedOption.value === this.kebabOptions.delete.value) {
        this.userSelectedForEdit = user;
        this.showConfirmModal = true;
      } else {
        this.openEditUserDrawer(user);
      }
    },
    openEditUserDrawer(user) {
      this.userSelectedForEdit = user;
      this.showUserDrawer = true;
    },
    closeUserDrawer() {
      this.showUserDrawer = false;
    },
    async deleteUser(user) {
      await this.$store.dispatch("deleteUser", user);
      this.$store.dispatch("fetchUsers").then(() => {
        this.$store.dispatch("resetFilterOrganization");
        this.resetProvider();
        this.$store.dispatch("filterUsersByRole", this.$store.state.filterRole);
      });
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchUsers").then(() => {
      this.$store.dispatch("filterUsersByRole", "all");
    });
    this.resetProvider();
  },
  watch: {
    // Watch for changes to the users array and trigger an update to the table
    users: function () {
      this.$nextTick(() => {
        // Force an update to the table
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.users {
  .select {
    margin-top: 21px;
  }
  &-table {
    border: 2px solid #f1f3f9;
    border-radius: 0.5em;
    margin-right: 6.5em;
    width: 100%;
    &-header {
      height: 2.5em;
      background-color: #f1f3f9;
      color: #222520;
      &-title {
        &-name,
        &-email,
        &-phone,
        &-org,
        &-role,
        &-last {
          font-size: 14px;
          padding-left: 1.35em;
          @media screen and (max-width: 860px) {
            font-size: 0.7em;
            padding: 0.25em 0.5em 0.25em 0.5em;
          }
          text-align: left;
          .arrow-up-down {
            vertical-align: middle;
            width: 1em;
            padding-left: 3px;
            padding-bottom: 3px;
          }
        }
        &-org {
          @media screen and (max-width: 650px) {
            width: 3em;
            word-break: break-all;
          }
        }
      }
    }
    &-content {
      cursor: pointer;
      &-title {
        text-align: left;
        padding: 1em 1.35em 1em;
        font-size: 14px;
        @media screen and (max-width: 860px) {
          font-size: 0.7em;
          padding: 0.25em 0.5em 0.25em 0.5em;
        }
        &-selectOptions {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          border: none;
          font-size: 15px;
          width: 1em;
          background-color: transparent;
          &:hover {
            cursor: pointer;
          }
          option:disabled {
            display: none;
          }
          &-option {
            font-size: 0.8em;
          }
        }
        &-email {
          @media screen and (max-width: 860px) {
            width: 5em;
            word-break: break-all;
          }
        }
        &-phone {
          @media screen and (max-width: 860px) {
            width: 5em;
            word-break: break-all;
          }
        }
        &-name {
          &-t1 {
            margin-top: 0;
            margin-bottom: 0;
          }
          &-t2 {
            margin: 0px;
            font-size: 10px;
          }
        }
        &-sym {
          background-color: #f1f3f9;
          color: #1d2433;
          border-radius: 100px;
          padding: 8px;
        }
      }
    }
  }
}
td {
  border-bottom: 2px solid #f1f3f9;
}
</style>
