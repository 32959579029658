<template>
  <div class="userManagment">
    <div class="userManagment-section">
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
        "
      >
        <button
          @click="selectedSection = 'Users'"
          class="userManagment-section-button"
          :class="{
            'button--transparent': selectedSection !== 'Users',
          }"
        >
          Users
        </button>
        <button
          @click="selectedSection = 'Roles&Permissions'"
          class="userManagment-section-rolesButton"
          :class="{
            'button--transparent': selectedSection !== 'Roles&Permissions',
          }"
        >
          Roles & Permissions
        </button>
      </div>
      <button
        v-if="this.selectedSection === 'Users'"
        class="userManagment-section-addUser"
        :disabled="
          roleLoggedUser !== 'SuperAdmin' && roleLoggedUser !== 'Admin'
        "
        @click="showAddUserModal = !showAddUserModal"
      >
        + Add User
      </button>
    </div>
    <component :is="selectedSectionComponent" />
    <Drawer :show.sync="showAddUserModal">
      <template>
        <UserForm @close="closeAddUserModal" :isEdit="false" />
      </template>
    </Drawer>
  </div>
</template>

<script>
import RolesPermissions from "./RolesPermissions.vue";
import Users from "./Users.vue";
import UserForm from "../Forms/UserForm.vue";
import Drawer from "../Shared/Drawer.vue";

export default {
  data() {
    return {
      selectedSection: "Users",
      showAddUserModal: false,
    };
  },
  components: { UserForm, Drawer },
  computed: {
    selectedSectionComponent() {
      switch (this.selectedSection) {
        case "Users":
          return Users;
        case "Roles&Permissions":
          return RolesPermissions;
        default:
          return Users;
      }
    },
    roleLoggedUser() {
      return this.$store.getters.roleLoggedUser;
    },
  },
  methods: {
    closeAddUserModal() {
      this.showAddUserModal = !this.showAddUserModal;
    },
  },
};
</script>

<style scoped lang="scss">
.userManagment {
  &-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5em;
  }
  &-form {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    &-left,
    &-right {
      display: flex;
      margin-right: 1em;
      flex-direction: column;
      gap: 15px;
      &-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        &-input {
          border-radius: 0.5em;
          border: solid 1px rgba(204, 204, 204, 0.829);
          height: 2.25em;
          width: 18em;
          padding-left: 0.5em;
        }
        &-label {
          font-size: 0.785em;
          font-weight: 700;
          padding-bottom: 0.5em;
        }
        &-select {
          height: 2.5em;
          background-color: transparent;
          border-radius: 0.5em;
          border: solid 1px rgba(204, 204, 204, 0.829);
        }
      }
    }
  }
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    cursor: not-allowed;
  }
}
</style>
