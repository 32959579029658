<template>
  <div
    style="
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    "
  >
    <form class="orgForm" @submit.prevent="submitForm">
      <div class="orgForm-header">
        <p class="orgForm-header-title">{{ action }} {{ type }}</p>
        <img
          class="orgForm-header-close"
          alt="x-icon"
          src="../../assets/x-icon.png"
          @click="closeForm"
        />
      </div>
      <div class="orgForm-form">
        <label class="orgForm-form-label" for="inputValue"
          >{{ type }} Name</label
        >
        <input
          class="orgForm-form-input"
          type="text"
          id="inputValue"
          name="inputValue"
          v-model="inputValue"
          required
        />
        <div class="submit">
          <LoadingButton
            type="submit"
            :text="`${action} ${type}`"
            :isLoading="isLoading"
          ></LoadingButton>
        </div>
      </div>
      <div class="error" v-if="serverResponse">
        <p class="form-error-message">{{ serverResponse }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import LoadingButton from "../Shared/LoadingButton.vue";
import { ToastMessages } from "../Shared/strings";

export default {
  data() {
    return {
      inputValue: this.name,
      serverResponse: "",
      isLoading: false,
    };
  },
  props: ["type", "action", "name", "id"],
  computed: {
    selectedOrg() {
      return this.$store.state.loggedUser.selectedOrg;
    },
  },
  components: { LoadingButton },
  methods: {
    async submitForm() {
      this.isLoading = true;
      this.serverResponse = "";
      if (this.action === "Add") {
        try {
          await this.$store.dispatch("addOrganizationAction", {
            name: this.inputValue,
            type: this.type.replace(/\s/g, ""),
            parentId: this.selectedOrg.id,
          });
          this.closeForm();
          this.$store.dispatch("showToast", {
            message: `${this.type} ${ToastMessages.orgs.add.success}`,
            type: "success",
          });
        } catch (err) {
          this.serverResponse = err.response.data.message;
          this.$store.dispatch("setToast", {
            message: ToastMessages.generic_error,
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      }

      if (this.action === "Edit") {
        try {
          await this.$store.dispatch("editOrganizationAction", {
            name: this.inputValue,
            id: this.id,
            type: this.type.replace(/\s/g, ""),
          });
          this.closeForm();
          this.$store.dispatch("showToast", {
            message: `${this.type} ${ToastMessages.orgs.edit.success}`,
            type: "success",
          });
        } catch (err) {
          this.serverResponse = err?.response.data?.message;
          this.$store.dispatch("showToast", {
            message: ToastMessages.generic_error,
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    closeForm() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss">
.orgForm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 2;
  width: 25em;
  border: 2px solid #f1f3f9;
  padding: 12px;
  border-radius: 8px;
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1em 0px 1em;
    border-bottom: 2px solid #f1f3f9;
    &-title {
      font-weight: 700;
    }
    &-close {
      width: 0.7em;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin: 12px;
    &-label {
      font-size: 16px;
      font-weight: 600;
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
    &-input {
      border-radius: 0.5em;
      border: solid 1px rgba(204, 204, 204, 0.829);
      height: 1.85em;
      padding-left: 0.5em;
      margin-bottom: 1em;
    }
    &-button {
      color: white;
      font-weight: 500;
      height: 2.25em;
      width: 6em;
      margin-bottom: 1em;
      background-color: #003366;
      border: none;
      border-radius: 0.5em;
      &:hover {
        cursor: pointer;
        background-color: #002b55;
      }
    }
    .submit {
      align-self: flex-end;
    }
  }
}
.userManagment-form-right-content-select {
  display: block;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.8em;
  width: 13em;
  margin-bottom: 1.25em;
  padding: 0.4em;
}
</style>
