var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings-page"},[_c('h2',{staticClass:"settings-page-title"},[_vm._v("Settings")]),_c('div',{staticClass:"settings-page-sections"},[_c('div',{class:{
        'settings-page-sections-selected': _vm.selectedSection === 'Profile',
      }},[_c('h3',{staticClass:"settings-page-sections-title",on:{"click":function($event){_vm.selectedSection = 'Profile'}}},[_vm._v(" "+_vm._s(_vm.tabNames.profile)+" ")])]),_c('div',{class:{
        'settings-page-sections-selected': _vm.selectedSection === 'Password',
      }},[_c('h3',{staticClass:"settings-page-sections-title",on:{"click":function($event){_vm.selectedSection = 'Password'}}},[_vm._v(" "+_vm._s(_vm.tabNames.password)+" ")])]),(_vm.role === 'Admin' || _vm.role === 'SuperAdmin')?_c('div',{class:{
        'settings-page-sections-selected':
          _vm.selectedSection === 'Org Management',
      }},[_c('h3',{staticClass:"settings-page-sections-title",on:{"click":function($event){_vm.selectedSection = 'Org Management'}}},[_vm._v(" "+_vm._s(_vm.tabNames.orgManagement)+" ")])]):_vm._e(),(_vm.role === 'Admin' || _vm.role === 'SuperAdmin')?_c('div',{class:{
        'settings-page-sections-selected':
          _vm.selectedSection === 'User Management',
      }},[_c('h3',{staticClass:"settings-page-sections-title",on:{"click":function($event){_vm.selectedSection = 'User Management'}}},[_vm._v(" "+_vm._s(_vm.tabNames.userManagement)+" ")])]):_vm._e()]),_c('div',{staticClass:"settings-page-separator"}),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }