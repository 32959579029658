<template>
  <div class="profile-section">
    <h2 class="profile-section-title">Profile</h2>
    <form
      @submit.prevent="updateMyData"
      class="profile-section-user actions-form"
    >
      <!-- First Name -->
      <div class="form-field">
        <label class="profile-section-user-left-label" for="firstName"
          >First Name</label
        >
        <input
          class="profile-section-user-left-input"
          type="text"
          id="firstName"
          name="firstName"
          v-model="firstName"
        />
      </div>
      <!-- Last Name -->
      <div class="form-field">
        <label class="profile-section-user-right-label" for="lastName"
          >Last Name</label
        >
        <input
          class="profile-section-user-right-input"
          type="text"
          id="lastName"
          name="lastName"
          v-model="lastName"
        />
      </div>
      <!-- Title -->
      <div class="form-field">
        <label class="profile-section-user-left-label" for="title">Title</label>
        <input
          class="profile-section-user-left-input"
          type="text"
          id="title"
          name="title"
          v-model="title"
        />
      </div>
      <!-- Email -->
      <div class="form-field">
        <label class="profile-section-user-right-label" for="email"
          >Email</label
        >
        <input
          class="profile-section-user-right-input"
          type="email"
          id="email"
          name="email"
          v-model="email"
        />
      </div>
      <!-- Telephone -->
      <TelInput :value="phone" @telValueChanged="onTelInputChange" />
      <!-- Submit -->
      <div class="submit">
        <LoadingButton
          type="submit"
          text="Save"
          :isLoading="isLoading"
        ></LoadingButton>
      </div>
    </form>

    <!-- Language and Time-Zone are not part of MVP so commenting out for now -->
    <!-- <h2 class="profile-section-language-title">Language & Region Settings</h2> -->
    <!-- <div class="profile-section-language">
      <div class="profile-section-language-left">
        <label class="profile-section-language-left-label" for="language"
          >Language</label
        >
        <select
          class="profile-section-language-left-select"
          id="language"
          name="language"
        >
          <option value="English">English</option>
        </select>
      </div>
      <div class="profile-section-language-right">
        <label class="profile-section-language-right-label" for="timezone"
          >Timezone</label
        >
        <select
          class="profile-section-language-right-select"
          id="timezone"
          name="timezone"
        >
          <option value="America/Detroit">America/Detroit</option>
        </select>
        <button
          @click="updateMyData()"
          class="profile-section-language-right-button"
        >
          Save
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
import TelInput from "../Forms/TelInput.vue";
import LoadingButton from "../Shared/LoadingButton.vue";
import { ToastMessages } from "../Shared/strings";

export default {
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      title: "",
      isLoading: false,
    };
  },
  components: { LoadingButton, TelInput },
  methods: {
    async updateMyData() {
      this.isLoading = true;
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      let user = {
        // ...loggedUser,
        currentOrganizationId: loggedUser.selectedOrg.id,
        email: this.email,
        firstName: this.firstName,
        id: this.id,
        isActive: true,
        lastName: this.lastName,
        newOrganizationId: loggedUser.selectedOrg.id,
        phone: this.phone,
        roleId: loggedUser.roleId,
        title: this.title,
      };
      await this.$store.dispatch("editUser", user);
      await this.$store.dispatch("updateCurrentUser");
      this.isLoading = false;
      this.$store.dispatch("showToast", {
        message: ToastMessages.user.profileUpdate.success,
        type: "success",
      });
    },
    onTelInputChange(data) {
      this.phone = data;
    },
  },

  created() {
    this.$store.dispatch("updateCurrentUser").then(() => {
      const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      this.firstName = loggedUser.firstName;
      this.lastName = loggedUser.lastName;
      this.email = loggedUser.email;
      this.id = loggedUser.id;
      this.phone = loggedUser.phone;
      this.title = loggedUser.title;
    });
  },
};
</script>

<style scoped lang="scss">
.profile-section {
  color: #1d2432;
  width: 50%;
  &-title {
    margin: 1em 0 2.5em;
    font-size: 20px;
    font-weight: 600;
  }
  &-user {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    font-size: 16px;
    font-weight: 600;
    .submit {
      grid-column-start: 1;
    }
  }
}
</style>
