<template>
  <div>
    <NavBar />
    <Settings class="container-under-navbar" />
  </div>
</template>

<script>
import Settings from "@/components/Settings/Settings.vue";
import NavBar from "../components/NavComponents/NavBar.vue";

export default {
  name: "default-component",
  props: {},
  model: {
    event: "updateModel",
  },
  components: { Settings, NavBar },
  created() {
    this.$store.dispatch("getLoggedUserData");
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style scoped></style>
