<template>
  <div class="filter-users">
    <p
      v-for="(section, index) in sections"
      :key="index"
      @click="handleFilterUsers(section.value)"
      class="filter-users-title"
      :class="{
        'filter-users-selected': selectedSection === section.value,
      }"
    >
      {{ section.label }}{{ section.value === "all" ? ` (${usersCount})` : "" }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedSection: "all",
      sections: [
        { label: "All", value: "all" },
        { label: "Super Admin", value: "SuperAdmin" },
        { label: "Admin", value: "Admin" },
        { label: "View / Edit", value: "ViewEdit" },
        { label: "View Only", value: "ViewOnly" },
        { label: "No Access", value: "NoAccess" },
      ],
    };
  },
  computed: {
    usersCount() {
      const users = this.$store.state.users;
      const uniqueUsers = new Set();
      users.forEach((user) => {
        uniqueUsers.add(user.id);
      });
      return uniqueUsers.size;
    },
    roles() {
      return this.$store.state.roles;
    },
  },
  methods: {
    getRolesName(role) {
      const foundRole = this.roles.find((elem) => elem.name === role);
      return foundRole ? foundRole.id : null;
    },
    handleFilterUsers(value) {
      this.selectedSection = value;
      this.$store.dispatch("resetFilterOrganization");
      this.$emit("resetOrganizationFilter");
      if (value === "all") {
        this.$store.dispatch("filterUsersByRole", this.selectedSection);
      } else {
        const roleId = this.getRolesName(value);
        this.$store.dispatch("filterUsersByRole", roleId);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filter-users {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 1em;
  &-title {
    color: #858689;
    font-weight: 600;
    height: 1.7em;
    font-size: 12px;
    @media screen and (max-width: 490px) {
      width: 4em;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &-selected {
    color: #272e3c;
    border-bottom: 2px solid #003366;
    @media screen and (max-width: 490px) {
      border-bottom: none;
    }
  }
}
</style>
