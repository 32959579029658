<template>
  <form
    novalidate
    class="actions-form"
    ref="userForm"
    @submit.prevent="checkForm"
  >
    <div class="actions-form-header">
      <p v-if="isEdit" class="actions-form-header-title">Edit User</p>
      <p v-else class="actions-form-header-title">Add User</p>
      <img
        class="actions-form-header-close"
        alt="x-icon"
        src="../../assets/x-icon.png"
        @click="closeForm"
      />
    </div>
    <fieldset :disabled="isLoading">
      <div class="actions-form-content">
        <!-- First Name -->
        <div class="form-field">
          <label class="required" for="firstName">First Name</label>
          <input
            class="userManagment-form-left-content-input"
            type="text"
            id="firstName"
            name="firstName"
            v-model="userData.firstName"
            required
            @focus="onFieldFocus"
          />
          <FormFieldError error="emptyRequiredField" />
        </div>
        <!-- Last Name -->
        <div class="form-field">
          <label class="required" for="lastName">Last Name</label>
          <input
            class="userManagment-form-left-content-input"
            type="text"
            id="lastName"
            name="lastName"
            v-model="userData.lastName"
            required
            @focus="onFieldFocus"
          />
          <FormFieldError error="emptyRequiredField" />
        </div>
        <!-- Title -->
        <div class="form-field">
          <label for="title">Title</label>
          <input type="text" id="title" name="title" v-model="userData.title" />
        </div>
        <!-- Email -->
        <div class="form-field">
          <label class="required" for="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            v-model="userData.email"
            required
            @focus="onFieldFocus"
          />
          <FormFieldError error="emptyRequiredField" />
        </div>
        <!-- Phone Number -->
        <TelInput :value="userData.phone" @telValueChanged="onTelInputChange" />
        <!-- Organization -->
        <div :class="{ hidden: this.selectedSuperAdmin }" class="form-field">
          <label
            :class="{ required: !this.selectedSuperAdmin }"
            for="organizationId"
            >Organization</label
          >
          <select
            class="userManagment-form-right-content-select"
            :required="!selectedSuperAdmin"
            v-model="userData.organizationId"
            :disabled="selectedSuperAdmin"
          >
            <option v-for="org in organizations" :value="org.id" :key="org.id">
              {{ org.name }}
            </option>
          </select>
          <FormFieldError error="emptyRequiredField" />
        </div>
        <!-- Role -->
        <div class="form-field">
          <label class="required" for="roleId">Role</label>
          <select
            class="userManagment-form-right-content-select"
            @change="getRoleId"
            :disabled="editUserIsSuperAdmin"
            v-model="roleSelected"
          >
            <option
              v-for="section in sections"
              :value="section"
              :key="section.value"
            >
              {{ section.label }}
            </option>
          </select>
          <FormFieldError error="emptyRequiredField" />
        </div>
        <div v-if="!isEdit" class="message">
          <p>
            Note: Adding a user will send the user an email link to create a
            password and login.
          </p>
        </div>
      </div>
      <div class="server-response" v-if="serverResponse">
        <p>{{ serverResponse }}</p>
      </div>
      <div class="actions-form-footer">
        <div class="submit">
          <LoadingButton
            type="submit"
            text="Save"
            :isLoading="isLoading"
          ></LoadingButton>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { validateForm, clearFieldError } from "./form-helpers";
import TelInput from "./TelInput.vue";

import FormFieldError from "../Shared/FormFieldError.vue";
import LoadingButton from "../Shared/LoadingButton.vue";
import { ToastMessages } from "../Shared/strings";
export default {
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    userSelectedForEdit: Object,
  },
  data() {
    return {
      serverResponse: "",
      userData: {
        id: "",
        firstName: "",
        lastName: "",
        title: "",
        email: "",
        phone: "",
        organizationId: "",
        roleId: "",
        isActive: true,
      },
      roleSelected: {},
      phone: "",

      isLoading: false,
      inti: null,
      phoneErrorMessage: "",
      phoneErrorMap: [
        "Invalid number",
        "Invalid country code",
        "Too short",
        "Too long",
        "Invalid number",
      ],
    };
  },
  components: { FormFieldError, LoadingButton, TelInput },
  computed: {
    roles() {
      return this.$store.state.roles;
    },
    sections() {
      const roleOptions = [
        { label: "Super Admin", value: "SuperAdmin" },
        { label: "Admin", value: "Admin" },
        { label: "View / Edit", value: "ViewEdit" },
        { label: "View Only", value: "ViewOnly" },
        { label: "No Access", value: "NoAccess" },
      ];
      if (this.roleLoggedUser !== "SuperAdmin") {
        roleOptions.shift();
      }
      return roleOptions;
    },
    organizations() {
      const current_org = this.$store.state.loggedUser.selectedOrg;
      const selected_org = [
        {
          id: current_org.id,
          name: current_org.name,
        },
      ];
      const org_providers = current_org.serviceProviders ?? [];
      return selected_org.concat(org_providers);
    },
    roleLoggedUser() {
      return this.$store.getters.roleLoggedUser;
    },
    loading() {
      return this.loaders > 0;
    },
    editUserIsSuperAdmin() {
      return this.isEdit && this.roleSelected.value === "SuperAdmin";
    },
    selectedSuperAdmin() {
      return this.roleSelected.value === "SuperAdmin";
    },
  },
  methods: {
    onFieldFocus(e) {
      clearFieldError(e.target);
    },
    onTelInputChange(data) {
      this.userData.phone = data;
    },
    getRoleId() {
      const foundRole = this.roles.find(
        (elem) => elem.name === this.roleSelected.value,
      );
      this.userData.roleId = foundRole.id;
    },
    getRoleName(id) {
      const foundRole = this.roles.find((elem) => elem.id === id);
      return foundRole ? foundRole.name : null;
    },
    getSection(roleName) {
      const foundSection = this.sections.find(
        (elem) => elem.value === roleName,
      );
      return foundSection ? foundSection : null;
    },
    submitUser() {
      this.checkForm();
    },
    addUser() {
      this.checkForm("add");
    },
    editUser() {
      this.checkForm("edit");
    },
    checkForm() {
      this.serverResponse = "";
      const formIsValid = validateForm([
        ...this.$refs.userForm.querySelectorAll("input, select"),
      ]);
      if (formIsValid) this.submitForm();
    },
    async submitForm() {
      const userData = { ...this.userData };
      if (userData.phone) {
        userData.phone = userData.phone.split(" ").join("");
      }
      if (this.isEdit) {
        userData.currentOrganizationId =
          this.userSelectedForEdit.organizationId;
        userData.newOrganizationId = this.userData.organizationId;
        delete userData["organizationId"];
      }
      if (!this.isEdit && this.roleSelected.value === "SuperAdmin") {
        userData.organizationId = this.$store.getters.getSelectedOrg.id;
      }
      this.isLoading = true;
      this.$store
        .dispatch(this.isEdit ? "editUser" : "addUser", userData)
        .then(() => {
          this.$store.dispatch("fetchUsers").then(() => {
            this.$store.dispatch("filterUsersByRole", "all");
            this.$store.dispatch("showToast", {
              message: `User ${this.isEdit ? "updated" : "added"} ${
                ToastMessages.user.edit.success
              }`,
              type: "success",
            });
            this.closeForm();
          });
        })
        .catch((error) => {
          this.serverResponse = error.response.data.message;
          this.$store.dispatch("showToast", {
            message: ToastMessages.generic_error,
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    closeForm() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.userSelectedForEdit) {
      this.userData.id = this.userSelectedForEdit.id;
      this.userData.firstName = this.userSelectedForEdit.firstName;
      this.userData.lastName = this.userSelectedForEdit.lastName;
      this.userData.title = this.userSelectedForEdit.title;
      this.userData.email = this.userSelectedForEdit.email;
      this.userData.phone = this.userSelectedForEdit.phone;

      this.userData.roleId = this.userSelectedForEdit.roleId;
      const roleName = this.getRoleName(this.userSelectedForEdit.roleId);
      this.roleSelected = this.getSection(roleName);
      this.userData.organizationId = this.userSelectedForEdit.organizationId;
    }
  },
};
</script>
<style scoped lang="scss">
@import "../Shared/forms.scss";
.message {
  grid-column: 1/-1;
  p {
    margin-top: 0;
  }
}
</style>
