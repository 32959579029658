<template>
  <div class="settings-page">
    <h2 class="settings-page-title">Settings</h2>
    <div class="settings-page-sections">
      <div
        :class="{
          'settings-page-sections-selected': selectedSection === 'Profile',
        }"
      >
        <h3
          class="settings-page-sections-title"
          @click="selectedSection = 'Profile'"
        >
          {{ tabNames.profile }}
        </h3>
      </div>
      <!-- Notifactions are not part of MVP so commenting them out for now. -->
      <!-- <div
        :class="{
          'settings-page-sections-selected':
            selectedSection === 'Notifications',
        }"
      >
        <h3
          class="settings-page-sections-title"
          @click="selectedSection = 'Notifications'"
        >
          Notifications
        </h3>
      </div> -->
      <div
        :class="{
          'settings-page-sections-selected': selectedSection === 'Password',
        }"
      >
        <h3
          class="settings-page-sections-title"
          @click="selectedSection = 'Password'"
        >
          {{ tabNames.password }}
        </h3>
      </div>
      <div
        v-if="role === 'Admin' || role === 'SuperAdmin'"
        :class="{
          'settings-page-sections-selected':
            selectedSection === 'Org Management',
        }"
      >
        <h3
          class="settings-page-sections-title"
          @click="selectedSection = 'Org Management'"
        >
          {{ tabNames.orgManagement }}
        </h3>
      </div>
      <div
        v-if="role === 'Admin' || role === 'SuperAdmin'"
        :class="{
          'settings-page-sections-selected':
            selectedSection === 'User Management',
        }"
      >
        <h3
          class="settings-page-sections-title"
          @click="selectedSection = 'User Management'"
        >
          {{ tabNames.userManagement }}
        </h3>
      </div>
    </div>
    <div class="settings-page-separator"></div>
    <router-view />
  </div>
</template>

<script>
import { Settings } from "../Shared/strings";

export default {
  data() {
    return {
      selectedSection: this.$route.name,
    };
  },
  methods: {},
  computed: {
    role() {
      return this.$store.getters.roleLoggedUser;
    },
    tabNames() {
      return Settings.tabs;
    },
  },
  watch: {
    selectedSection() {
      switch (this.selectedSection) {
        case "Profile":
          this.$router.push("/settings/profile");
          break;
        case "Password":
          this.$router.push("/settings/password");
          break;
        case "Org Management":
          this.$router.push("/settings/organization");
          break;
        case "User Management":
          this.$router.push("/settings/users");
          break;
      }
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("fetchRoles"),
      this.$store.dispatch("fetchUsers"),
      this.$store.dispatch("fetchOrganizations"),
    ]).then(() => {
      this.$store.dispatch("filterUsersByRole", "all");
    });
  },
};
</script>

<style scoped lang="scss">
.settings-page {
  margin: 2em 6.5em;
  color: #1d2432;
  @media screen and (max-width: 767px) {
    margin: 2em 1em 2em 1em;
  }
  &-title {
    font-size: 24px;
    font-weight: 600;
  }
  &-sections {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    @media screen and (max-width: 767px) {
      font-size: 0.65em;
    }
    @media screen and (max-width: 410px) {
      font-size: 60%;
    }
    &-title {
      &:hover {
        cursor: pointer;
      }
      font-size: 16px;
      font-weight: 600;
    }
    &-selected {
      margin-top: 2px;
      border-bottom: 2px solid #003366;
    }
  }
  &-separator {
    position: absolute;
    left: -6.5em;
    border-bottom: 1px solid #e6e7ea;
    width: calc(100% + 13em);
  }
  h3 {
    font-size: 16px;
  }
}
</style>
