<template>
  <div class="form-field form-field--tel" :class="{ error: errorMessage }">
    <label for="phone"> Phone Number </label>
    <input
      type="tel"
      class="userManagement-form-right-content-input"
      id="phone"
      @blur="onBlur"
      @input="$emit('telValueChanged', $event.target.value)"
      ref="phone"
      :value="value"
    />
    <FormFieldError :customMessage="errorMessage" />
  </div>
</template>

<script>
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import FormFieldError from "../Shared/FormFieldError.vue";

export default {
  data() {
    return {
      errorMessage: "",
      errorMap: [
        "Invalid number",
        "Invalid country code",
        "Too short",
        "Too long",
        "Invalid number",
      ],
      inti: null,
    };
  },
  props: ["value"],
  components: { FormFieldError },
  methods: {
    onBlur() {
      this.errorMessage = "";
      if (
        this.$refs.phone.value.trim() &&
        this.$refs.phone.value !==
          `+${this.inti.getSelectedCountryData().dialCode}`
      ) {
        if (!this.inti.isValidNumber()) {
          const errorNum = this.inti.getValidationError();
          if (!errorNum || errorNum > 4 || errorNum < 0) {
            this.errorMessage = this.errorMap[4];
          } else {
            this.errorMessage = this.errorMap[errorNum];
          }
        } else {
          // Phone number is valid
          this.errorMessage = "";
        }
      } else {
        // Phone number is empty (but it's an optional field)
        this.errorMessage = "";
      }
    },
  },
  mounted() {
    this.inti = intlTelInput(this.$refs.phone, {
      autoInsertDialCode: true,
      nationalMode: false,
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
    });
  },
};
</script>

<style scoped lang="scss">
@import "../Shared/forms.scss";
</style>
